import IndexLayout from '../layouts';
import Wrapper from '../components/Wrapper';
import SiteNav from '../components/header/SiteNav';
import { SiteHeader, outer, inner, SiteMain } from '../styles/shared';
import * as React from 'react';
import { css } from 'react-emotion';
import { PostFullHeader, PostFullTitle, NoImage, PostFull } from '../templates/post';
import { PostFullContent } from '../components/PostContent';
import Footer from '../components/Footer';
import Helmet from 'react-helmet';

const PageTemplate = css`
  .site-main {
    background #fff;
    padding-bottom: 4vw;
  }
`;


const OurTeam: React.SFC = () => (
  <IndexLayout>
    <Helmet>
      <title>Our Team</title>
    </Helmet>
    <Wrapper className={`${PageTemplate}`}>
      <header className={`${SiteHeader} ${outer}`}>
        <div className={`${inner}`}>
          <SiteNav />
        </div>
      </header>
      <main id="site-main" className={`site-main ${SiteMain} ${outer}`}>
        <article className={`${PostFull} post page ${NoImage}`}>
          <PostFullHeader>
            <PostFullTitle>Our Team</PostFullTitle>
          </PostFullHeader>

          <PostFullContent className="post-full-content">
            <div className="post-content">
              <p>
                <h1>Michael Blythe</h1>
                <h2>Director at Yellow Mango | AWS Certified Cloud Practitioner | Experienced Business Solutions Architect | Agile </h2>
              </p>        
              
<h2>Join us</h2>
<p>We are always looking for talented Cloud Architects, DevOps specialists to join our team at <strong>Built On Cloud</strong>. Whether you have experience in <strong>AWS</strong>, <strong>Google Cloud</strong> or <strong>Microsoft Azure</strong> we&#39;d love to hear from you.</p>
<h3>Associates</h3>
<ul>
<li>test</li>
<li>test</li>

</ul>
<p>&nbsp;</p>
              
              <p>
                If you like to get in touch with our team <a href="/contact">contact us</a>, we'd love to hear from you.
              </p>
            </div>
          </PostFullContent>
        </article>
      </main>
      <Footer />
    </Wrapper>
  </IndexLayout>
);

export default OurTeam;
